import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { MdKeyboardArrowRight, MdFileUpload } from "react-icons/md";
import image from '../../../../assets/image-admin/Image.png';
import './AjouSecteur.css';
import { Input } from "../../../common/Input";
import Select from "react-select/dist/declarations/src/Select";
import UseAddOrUpdateSecteurForm from "./UseAddOrUpdateSecteurForm";
import ErrorMessage from "../../../common/ErrorMessage";
import { Link, NavLink, useParams, useLocation, useNavigate} from 'react-router-dom';


const AjouSecteur = () => {

  const { slug } = useParams<{ slug: string }>();

  let { state } = useLocation();


  const {
    register,
    errors,
    setValue,
    isLoading,
    onSubmit,
    image,
    cardImg
} = UseAddOrUpdateSecteurForm(state);

  return (
    <div className="pt-5">
      <div className="d-flex justify-content-between m-5 items-center ">
        <h4 className='fw-bold title-form-register'>Ajouter un secteur</h4>
      </div>
      <div className="add-sub-admin-form-wrapper m-4">
          <form onSubmit={onSubmit}>
              <div className="form-content">
                  <div className="row custom-row">
                      <div className="col-md-12 col-lg-12 mb-3 aptm-wrapper">
                          <Input
                              label="Nom"
                              type="text"
                              customClass="modal__input"
                              placeholder="Nom"
                              id="__nom"
                              {...register("nom")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.nom &&
                                  errors?.nom?.message
                              }
                          />
                      </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <textarea
                            id="description"
                            className={`form-control form-control-modal-custom`}
                            placeholder="Description"
                            cols={30}
                            rows={5}
                            {...register("description")}
                            ></textarea>
                            <ErrorMessage
                                message={
                                    errors?.description &&
                                    errors?.description?.message
                                }
                            />
                        </div>
                    </div>
                  </div>
              </div>
              <div className="pt-3 btn-wrapper d-flex align-items-center justify-content-end gap-3 flex-wrap pb-3">
                  <button
                      title="Annuler"
                      type="button"
                      className="btn custom_take__btn modal__btn"
                  >
                      Annuler
                  </button>
                  <button
                      title="Ajouté"
                      type="submit"
                      className="btn modal__btn blue__btn"
                      disabled={isLoading}
                  >
                      {!isLoading
                          ? !slug
                              ? "Ajouté"
                              : "Modifié"
                          : "Loading..."}
                  </button>
              </div>
          </form>
      </div>
    </div>
  );
};

export default AjouSecteur;
