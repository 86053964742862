import React from "react";
import om from "../../../../../assets/images/icons/om.png";
import wave from "../../../../../assets/images/icons/wave.png";
import chart from "../../../../../assets/images/icons/chart.png";
import livreur from "../../../../../assets/images/icons/livreur.png";
import ErrorMessage from "../../../../common/ErrorMessage";
import visa from "../../../../../assets/images/icons/visa.png";
import money_check from "../../../../../assets/images/icons/mch.png";

const ModePaiement = ({ setIndex, register, errors, vendeur }) => {
  return (
    <div className="form-register-tabs-component mt-5">
      <p className="custom_validate_cart_step__title">
        Choisir un mode de paiment
      </p>
      <div className="content-form-register-tabs mt-5">
        <div className="row-add mb-4">
            {vendeur?.wave_api_key && (
              <div className="col-form-register-tabs mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="content-col-form-livraison position-relative cpntrol">
                      <label className="label-checkbox-register-tabs d-flex align-items-center">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          {...register("mode_de_paiement")}
                          value="WAVE"
                        />
                        Wave 
                      </label>
                      <div className="icon-mode-paiement-position">
                        <img loading="lazy" src={wave} alt="wave" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"><div></div></div>
                </div>
              </div>
              )}
          {/*    <div className="col-form-register-tabs mb-3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="content-col-form-livraison position-relative cpntrol">
                      <label className="label-checkbox-register-tabs d-flex align-items-center">
                        <input
                          type="radio"
                          className="input-checkbox-register-tabs me-2"
                          {...register("mode_de_paiement")}
                          value="ORANGE_SN_API_CASH_OUT"
                        />
                        Orange Money 
                      </label>
                      <div className="icon-mode-paiement-position">
                        <img loading="lazy" src={om} alt="orange" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"><div></div></div>
                </div>
              </div>*/}
          {vendeur?.stripe_secret_key && (
            <div className="col-form-register-tabs mb-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="content-col-form-livraison position-relative cpntrol">
                    <label className="label-checkbox-register-tabs d-flex align-items-center">
                      <input
                        type="radio"
                        className="input-checkbox-register-tabs me-2"
                        {...register("mode_de_paiement")}
                        value="STRIPE"
                      />
                      Stripe
                    </label>
                    <div className="icon-mode-paiement-position">
                      <img loading="lazy" src={visa} alt="carte" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div></div>
                </div>
              </div>
            </div>
          )}
          <div className="col-form-register-tabs mb-3">
            <div className="row">
              <div className="col-md-6">
                <div className="content-col-form-livraison position-relative cpntrol">
                  <label className="label-checkbox-register-tabs d-flex align-items-center">
                    <input
                      type="radio"
                      className="input-checkbox-register-tabs me-2"
                      {...register("mode_de_paiement")}
                      value="CASH"
                    />
                    Paiement à la livraison
                  </label>
                  <div className="icon-mode-paiement-position">
                    <img loading="lazy" src={money_check} alt="carte" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div></div>
              </div>
            </div>
          </div>
          <ErrorMessage
            message={
              errors?.mode_de_paiement && errors?.mode_de_paiement?.message
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ModePaiement;
