import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { SecteurFormData,Secteur } from "../../../../utils/api/secteur/secteur.type";
import {
    useAddOrEditSecteurMutation,
    useDeleteSecteurMutation
} from "../../../../utils/api/secteur/secteur.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/hooks";
import { BsTrashFill } from "react-icons/bs";
import { Link, NavLink, useParams, useLocation, useNavigate} from 'react-router-dom';


function UseAddOrUpdateSecteurForm(secteur?: Secteur) {
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        description: yup.string().required().label("La description"),
        nom: yup.string().required().label("Le nom"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        clearErrors,
        control,
    } = useForm<SecteurFormData>({
        resolver: yupResolver(validationSchema),
    });

    const [cardImg, setCardImg] = useState<any>(null);
    const [image, setImage] = useState<File | null>(null);

    const [addOrEditSecteur, { isLoading, isSuccess, error, isError, data }] =
        useAddOrEditSecteurMutation();

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                icon: "success",
                title: `Secteur ${secteur ? "modifié" : "ajouté"} avec succès !`,
                iconColor: Color.success,
                showConfirmButton: false,
                timer: 3000,
            }).then(() => {
                reset();
                navigate("/admin/Secteurs")
            });
        }
        const err = error as any;
        if (isError) {
            console.log("error", err);
            if (
                err?.data?.email?.includes(
                    "Un utilisateur avec cet email existe déjà."
                )
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Un utilisateur avec cet email existe déjà.",
                    showConfirmButton: false,
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: err?.data?.message
                        ? err?.data?.message
                        : `Une erreur de statut ${err?.status} est survenue `,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }
    }, [error, isError, isSuccess]);
    useEffect(() => {
        cleannerError(errors, clearErrors);
    }, [clearErrors, errors]);

    useEffect(() => {
        if (image?.name) {
            //setValue("avatar", image);
            setCardImg(URL.createObjectURL(image));
        }
    }, [image]);


    useEffect(() => {
        if (secteur?.id) {
            let fields: (keyof SecteurFormData)[] = [
                "id",
                "slug",
                "nom",
                "description",
            ];
            for (let field of fields) {
                register(field);
                if (field) {
                    setValue(field, secteur[field]);
                }
            }
        }
    }, [secteur]);

    const onSubmit = async (data: SecteurFormData) => {
        const fd = new FormData();
        for (let key of Object.keys(data)) {
            fd.append(key, data[key]);
        }
        await addOrEditSecteur({ slug: secteur?.slug, data: fd });
    };

    return {
        register,
        errors: errors,
        onSubmit: handleSubmit(onSubmit),
        setValue,
        isLoading,
        control,
        image,
        cardImg,
    };
}

export function DeleteSecteur({ item }: { item: Secteur }) {
    const [deleteItem, { isSuccess, isError, error }] = useDeleteSecteurMutation();
    const onDelete = useDelete<Secteur>({
        item,
        deleteItem,
        isSuccess,
        isError,
        error,
        question: "cet secteur",
        successMessage: "Secteur supprimé",
    });
    return (
        <button
            
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E" }}
            onClick={onDelete}
        >
            <BsTrashFill />
        </button>
    );
}


export default UseAddOrUpdateSecteurForm;
