import React, { useState } from 'react';
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { MdKeyboardArrowRight, MdFileUpload } from "react-icons/md";
import image from '../../../../assets/image-admin/Image.png';
import './AjouSecteur.css';
import { Link, NavLink, useParams, useLocation, useNavigate} from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";


const DetailSecteurs = () => {
  const { slug } = useParams<{ slug: string }>();
  let { state } = useLocation();
  const navigate = useNavigate();


  return (
    <div className="form-register-tabs-component mt-10">
      
      <div className="content-detail-commande-page m-5 ">
        <div className="row">
          <div className="content-title-form-register pb-4">
            <h3
              className="title-form-register mt-5"
              style={{ cursor: "pointer" }}
            >
              <span className="">Details Secteur</span>{" "}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3 col-left-detail-commande">
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Non du Secteur</h3>
                <div className="sm-value">
                  {state?.nom}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3 col-left-detail-commande">
            <div className="card-detail-commande-item mb-3">
              <div className="content-col-detail-commande-admin">
                <h3 className="title-col-detail-commande-admin">Description</h3>
                <div className="sm-value">
                  {state?.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSecteurs;
