import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { TypeQuery,PaginationResults } from "../user/user.type";
import { Secteur, SecteurFormData } from "./secteur.type";

export const SecteurApi = createApi({
  reducerPath: "secteurApi",
  tagTypes: [
    "secteur",
    "secteurList",
    "secteurByVisitor",
    "secteurBySlug",
    "allSecteurList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListSecteur: builder.query<any, TypeQuery>({
      providesTags: ["secteurList"],
      query: (query) => QueryUrl("secteurs", query),
    }),
    getListsecteurAdmin: builder.query<PaginationResults<Secteur>, TypeQuery>({
      providesTags: ["secteurList"],
      query: (query) => QueryUrl("secteurs", query),
    }),
    getSecteurList: builder.query<PaginationResults<Secteur>, TypeQuery>({
      providesTags: ["secteur"],
      query: (query) => QueryUrl("payment-method/", query),
    }),
    addOrEditSecteur: builder.mutation<Secteur,{ slug?: string; data: SecteurFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            SecteurApi.util.invalidateTags([
              "secteurList",
              "secteurByVisitor",
              "secteur",
            ])
          );
        }, 1000);
      },
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `secteurs/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `secteurs/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteSecteur: builder.mutation<Secteur, string>({
      query: (slug) => ({
        url: `secteurs/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "secteur",
        "secteurList",
        "secteurByVisitor",
      ],
    }),
    secteurBySlug: builder.query<Secteur | any, string>({
      query: (id) => `payment-method/${id}/`,
      providesTags: ["secteurBySlug"],
    }),
  }),
});

export const {
  useAddOrEditSecteurMutation,
  useDeleteSecteurMutation,
  useGetListsecteurAdminQuery,
  useGetListSecteurQuery,
  useGetSecteurListQuery,
} = SecteurApi;
